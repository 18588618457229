import styled from 'styled-components';
import { DropdownButton } from "react-bootstrap";

const Container  = styled.div`
width: 100%;
padding: ${({pagePadding}) => pagePadding ? '0px' : '0px 25px'} ;

.mainContainer{
  width: 100%;
  max-width: 1246px;
  margin: auto;
}
.year-dropdown{
  display: flex;
  margin: auto;
  margin-right: 10px;
}
`;


const Heading  = styled.div`
width: 100%;
display: flex;
margin-bottom 15px;
.challenge{
  font-family: "Rubik-Medium";
  font-size: 18px;
  line-height: 21px;
  color:#0D4270;
  display: flex;
  margin: auto;
  margin-left:0px;
}
`;

const CardWrapper  = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
`;

const ChallengeCard  = styled.div`
  width:${({width})=> width ? width : 'calc((100% - 50px) / 3)'};
  margin-right:${({margin})=> margin ? margin : '0px'};
  display: flex;
  border-radius: 6px;
  /* margin-bottom 25px; */
  cursor: pointer;
  position: relative;
  box-shadow: 0px 8px 25px 0px #0D427040;
`;

const TypeContainer = styled.div`
  position: absolute;
  top:10px;
  left:10px;
  background-color: white;
  padding: 0px 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #005c87;
  border-radius: 3px;
  text-transform: capitalize;
`

const ChallangeTypeCard = styled.div`
position:absolute;
top:10px;
left:10px;
background: white;
padding: 0px 10px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
color: #005c87;
border-radius: 3px;
text-transform: capitalize;
`

const ImageCard  = styled.div`
  width: 499px;
  height:${({height})=> height ? height : '315px'};
  cursor: pointer;
  div{
    width: 100%;
  height:${({height})=> height ? height : '315px'};
  cursor: pointer;
    >img{
    width:100%;
    height:100%;
    border-radius: 6px 0 0 6px;
  }

  }
  >img{
    width:100%;
    height:100%;
    border-radius: 6px 0 0 6px;
  }
`;

const BottomCard  = styled.div`
  width: 754px;
  /* display: flex; */
  border-radius: 0 6px 6px 0;
  background: white;
  /* flex-wrap: wrap; */
  padding: 0px 15px 0px 25px;
  .challenge-name{
    width: 100%;
    display: flex;
    margin-top: 25px;
    margin-bottom: 15px;
    gap:20px;
    .name{
        width:auto;
        font-family: "Rubik-Medium";
        font-size: 24px;
        line-height: 24px;
        padding-bottom: 3px;
        color:#005c87;
        display: block;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .icon{
        height:24px;
        width: 24px;
        display: flex;
        margin: auto 0;
        /* >img{
            width:25px;
            height:25px;
        } */
    }
  }
.date{
  width: 100%;
  font-family: Rubik-medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color:#005c87;
  display: flex;
  height:fit-content ;
  margin-bottom: 10px;
}
.participant-card{
  width: 100%;
  display: flex;
  height:fit-content ;
  .days{
    display: flex;
    font-family: Rubik-medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #005c87;
    margin-bottom: 27px;

}
}
.button{
  width: 300px;
  display: flex;
  height: 50px;
  margin-bottom:15px;
  background: #005c87;
  cursor: pointer;
  font-family: "Rubik-Medium";
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 8px 24px 0px #005C8780;
}
`;

const Completed = styled.div`
      display: flex;
      font-family: "Rubik";
      font-size: 14px;
      line-height: 20px;
      color:#0d4270;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      margin:10px 0px 14px 0px;
      height: 40px;
      width: 100%;
      background-color: #f6f6f6;
      border-radius: 6px;
      & > b {
        color : #69c2ff;
        margin:0px 3px
      }
`

const CustomDropDown = styled(DropdownButton)`
  width: 100%;
  display: ${({inputdisplay}) => inputdisplay ? inputdisplay : 'inline-block'};
  color: ${({newWidth}) => newWidth ? 'white' : 'white'};
  background-color: ${({newWidth}) => newWidth ? '#0D4270' : '#ffffff'};
  align-items: center;
  padding: 0px 10px;
  border: 1px solid #cdcbcb;
  text-align: left;
  border: none;
  padding: ${({inputpadding,newWidth}) => inputpadding ? inputpadding : newWidth ? '9px 16px' : '9px 29px'};
  border-radius: 3px;
  color: #9c9c9c;
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Rubik-Medium';
    float: left;
    max-width:118px;
    white-space:nowrap;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
    text-transform: capitalize;
  }
  > ul {
    max-width: 300px !important;
  }
  font-size: 14px;
  letter-spacing: 0px;
  color: #282C37;
  font-family: "Rubik-Medium";
  color: #9c9c9c;
  .caret {
    float: right;
    margin-top: 5px;
    display: flex;
    align-self: center;
    color: rgba(105, 194, 255, 1);
    border-width: 9px;
  }
  > li > a {
    text-overflow: ellipses;
    white-space: normal;
    overflow: hidden;
  }
  > span {
    color: #a3a3a3;
    float: right;
    border-top: 6px dashed;
    margin-top: 7px;
  }
  &:active{
    color: #0D4270;
  }
`;

const YearDropDown = styled(CustomDropDown)`
  float: right;
  align-items: center;
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 90px;
  height: 40px;
  background-color: rgba(156, 156, 156, 0.1);
  border-radius: 6px;
  box-shadow: none;
  border:1px solid #9C9C9C;
  margin-top: ${({role}) => (role === 'ADMIN') ? '0px' : '0px'};
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }
`;

const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
text-align: center;
color: #9C9C9C;
display: flex;
margin: auto;
margin-right: 10px;
`;

const NoData = styled.div`
width: 100%;
display: flex;
font-family: "Rubik-Bold";
font-size: 18px;
line-height: 20px;
text-align: center;
color: #0D4270;
`;

export { Container, Heading, CardWrapper, ChallengeCard,Completed,TypeContainer,ChallangeTypeCard, ImageCard, BottomCard, CustomDropDown, YearDropDown, NewLabel, NoData };