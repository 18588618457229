import styled from 'styled-components';
import {Modal} from "react-bootstrap";
import {PlayIcon, VideoPlayer} from "../../Content/DailyDashboard/styles";
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';
const StyledBody = styled(Modal.Body)`
  padding: 0px;
`;
const StyledModal = styled(Modal)`

.modal-body{
  padding:unset;

}
  .modal-dialog {
    width: 360px;
  }
  .modal-content{
    border-radius: 6px;
  }
  .modal-header{
    background:linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%);
    border-radius:6px 6px 0px 0px;
    height:50px;
    .close{
      color:white;
      opacity:inherit;
    }
    >div{
      text-align:center;
    }
    span{
      color:white;
      font-weight:500;
      font-size:18px;
      line-height:24px;
      font-family:${MediumFont}
    }
  }
  .modal-backdrop.in{
    opacity: .2;
  }
  .modal-dialog {
    transform: translate(-50%, -50%);
  }
  .info-popup { 
    top: 30%;
    width: 485px;
    margin: 0 auto;
    .modal-header {
      border-bottom: 0px;
    } 
  }
 @media (max-width: 500px){
   .modal-dialog {
    width: 80%;
    }
 }
 @media (min-width: 768px) {
  .modal-content{
    margin-left: ${({height}) => height ? "-150px" : '0px' };
    margin-top: ${({height}) => height ? "-150px" : '0px' };;
  }
}
`;

const ChallengeInfo = styled.div`
  width: 100%;
  padding: 0px 25px 25px;
  > div: first-child {
    color: #0D4270;
    font-size: 15px;
    margin: 10px 0px 0px 0px;
  }
  > div{
    font-family: ${RegularFont};
    font-size: 21px;
    color: #0D4270;
    line-height: 26px;
    text-align: center;
  }
  > div:nth-child(2){
    margin: 0px 0px 10px 0px;
    p{
      font-family: ${MediumFont};
    font-size: 16px;
    color: #0D4270;
    line-height: 24px;
    text-align: center;
    margin: 0px 0px 10px 0px;

    }

  }
  .que{
    font-size: 16px;
    opacity: 0.8;
    color:#0D4270;
    margin: 20px 0px 15px 0px;
    font-weight:500;
    line-height:24px
    font-family: ${MediumFont};
    text-align:center
  }
  .btns{
    display:flex;
    justify-content:space-between;
    font-family: ${MediumFont};
    .btn1{
      background:#FD7175;
      color:white;
      border:none;
      width:200px;
      text-decoration:none;
      border-radius:4px;
      height:50px;
    }

    .btn2{
      background:white;
      color:#FD7175;
      border:1px solid #FD7175;
      width:200px;
      border-radius:4px;
      height:50px;
    }
  }
`;
const PlayIconVideo = styled(PlayIcon)`
  margin-left: 0px;
`;
const VideoPlayerContainer = styled(VideoPlayer)`
  margin-left: 55px;
`;
export { StyledModal, StyledBody, ChallengeInfo, PlayIconVideo, VideoPlayerContainer}
