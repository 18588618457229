import React from "react";
import PropTypes from "prop-types";
import { ImageUrl } from "../../utils/constants";
import {
  convertDateInTimezone,
  convertMonthFromDate,
  // getChallengeTimeToShow,
  getChallengeTimeToShowNew,
  getStatus,
} from "../../utils/methods";
import moment from "moment";
import momentTZ from "moment-timezone";
import {
  ChallengeCard,
  ImageCard,
  BottomCard,
} from "../ChallengeDashboardV2/FeatureChallengeStyle";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import LazyImage from "../common/LazyImage/LazyImage";
import parse from 'react-html-parser';

class FeatureCompanyChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge),
      isButtonDisabled: false
    };
  }

  componentDidMount() {
    const { challenge } = this.props;
    this.setState({
      time: getChallengeTimeToShowNew(challenge, this.props),
    });
  }

  componentDidUpdate() {
    const { isDummyChallenge, history } = this.props;
    if (isDummyChallenge) {
      history.push(
        `/challenges/${this.props.challenge.id}${
          isDummyChallenge ? "?ACME=true" : ""
        }`
      );
    }
  }

  challengeNavigation = (challengeId, isDummy) => {
    const { history, toggleDummyChallenges } = this.props;
    if (isDummy) {
      toggleDummyChallenges(true);
    } 
    history.push(`/challenges/${challengeId}${isDummy ? "?ACME=true" : ""}`);
    // else {
    //   history.push(`/challenges/${challengeId}`);
    // }
  };

  joinChallenge = (challengeId, isJoined, event) => {
    const { joinOrLeaveChallenge } = this.props;
    event.stopPropagation();
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined,
    };
    joinOrLeaveChallenge(data);
    this.setState({ isButtonDisabled: true });
    window.setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 2000);
  };
  render() {
    const { time, isButtonDisabled } = this.state;
    const { challenge, t } = this.props;
    let challengeId = challenge.id;
    let challengeOver = true;
    time.map((step) => {
      const values = step.split(" ");
      if (
        values &&
        (values[0] === "Starts" ||
          values[0] === "Last" ||
          parseInt(values[0],10) > 0)
      ) {
        challengeOver = false;
      }
    });
    const DaysLeft = time.map((step) => step);
    const joinOrLeave =
      challenge.id !== 53
        ? !challengeOver && (challenge.challenge_status === "joined" ? 0 : 1)
        : challenge.challenge_status === "joined"
          ? 0
          : 1;
    const lang = localStorage.getItem("lang");
    return (
      <ChallengeCard width="100%">
        <ImageCard
          onClick={() =>
            this.challengeNavigation(challengeId, challenge.is_dummy_challenge)
          }
        >
          <LazyImage src={`${ImageUrl}/${challenge.image}`} alt="challenge" />
          <span style={{position:'absolute', fontFamily:"rubik-regular" ,top:'10px', left:'10px', padding:'5px 14px', color:'#005c87', fontSize:'12px', borderRadius:'3px', background:'white'}}>
            {challenge.challenge_type === 'survivor'?"Survivor":
              challenge.challenge_type === 'group' && challenge.is_team_challenge != 1 ? t("Classic"):
                challenge.challenge_type === 'new_group'? "Group":
                  challenge.challenge_type === 'group' && challenge.is_team_challenge === 1?"Team":"Behavior"} {t("Challenge")}
          </span>
        </ImageCard>
        <BottomCard
          onClick={() =>
            this.challengeNavigation(challengeId, challenge.is_dummy_challenge)
          }
        >
          <div
            className="challenge-name"
            onClick={() =>
              this.challengeNavigation(
                challengeId,
                challenge.is_dummy_challenge
              )
            }
          >
            <div className="name">{t(challenge.title)}</div>
            <LazyImage
              div={"icon"}
              src={`${ImageUrl}/${challenge.event_icon}`}
              alt="event-type"
            />
          </div>
          <div style={{
            width: '642px',
            color: '#669db7',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: "justify",
            fontSize:'16px',
            fontWeight:400,
            lineHeight:'20px',
            display: '-webkit-box',
            '-webkit-line-clamp': 4,
            '-webkit-box-orient': 'vertical',
            height: '80px',  
            marginBottom:'15px',
            fontFamily:'rubik-regular'
          }}>
            {parse(t(challenge.body))}
            {/* {t(challenge.body)} */}
          </div>
          <div 
            className="date"
          >
            {t("Start Date")} : {( lang !== 'fr' ) ? (convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format(
              "DD MMM, YYYY"
            ), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format(
              "DD MMM YYYY"
            ), this.props))}
          </div>
          <div
            className="participant-card"
            onClick={() =>
              this.challengeNavigation(
                challengeId,
                challenge.is_dummy_challenge
              )
            }
          >
            {/* {challengeAttendees && challengeAttendees.length > 0 && (
              <div className="count">{challengeAttendees.length}+</div>
            )} */}
            {/* <div className="participant">
              {challengeAttendees && challengeAttendees.length > 0
                ? t("Joined")
                : t("Nobody Joined")}
            </div> */}
            <div className="days">
              {DaysLeft === "Starts Today" || DaysLeft === "Last Day" ? null : (
                <div>
                  {convertDateInTimezone(challenge.start_date)._d <= momentTZ()._d
                    ? t("Days To End: ") + time[0] // Challenge has already started
                    : t("Days To Start: ") + time[0]}{" "}
                </div>
              )}
            </div>
          </div>
          <div
            className="button"
            onClick={isButtonDisabled?null:(event) => this.joinChallenge(challenge.id, joinOrLeave, event)}
          >
            {t("Join Challenge")}
          </div>
        </BottomCard>
      </ChallengeCard>
    );
  }
}

FeatureCompanyChallenge.propTypes = {
  challenge: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  featuredWith: PropTypes.number,
  toggleDummyChallenges: PropTypes.func,
  isDummyChallenge: PropTypes.bool,
  padding: PropTypes.string,
  joinOrLeaveChallenge: PropTypes.func.isRequired,
  t: PropTypes.func,
  challengeAttendees: PropTypes.array
};

const mapStateToProps = (state) => ({
  challengeAttendees: state.challenges.challengeAttendees,
});

export default connect(mapStateToProps, null)(withTranslation()(FeatureCompanyChallenge));
